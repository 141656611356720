<template>
  <content-wrapper>
    <article>
      <div class="product-landing-grid">
        <div class="product-description">
          <h2>{{ product?.name }}</h2>
          <p>{{ product?.description }}</p>
          <div v-if="product?.alternatives?.length">
            <button class="button" @click="expanded = !expanded">see sizes/styles</button>
            <ul :class="{ expanded: expanded }" class="expanding-list">
              <li
                v-for="alternative in product?.alternatives"
                :key="alternative"
              >
                {{ alternative }}
              </li>
            </ul>
          </div>
          <p v-html="product?.longDescription"></p>
          <div class="center">
            <router-link
              to="/contact#contact"
              class="button"
            >
              Get a quote
            </router-link>
          </div>
        </div>
        <image-carousel
          v-if="product?.images"
          :images="product?.images"
        />
      </div>
      <div class="product-description">
        <h3>Lumber/Pallet Heat Treatment Service</h3>
        <p>
          Heat treatment is a requirement for wooden packaging used for international
          transportation. International shipments should be in compliance with
          <a href="https://ispm15.com/">International Standards For Phytosanitary Measures No. 15 (ISPM 15)</a>,
          which aims to secure coordinated, effective action to prevent and to control
          the introduction and spread of pests of plants and plant products.
          <br><br>
          During the heat treating process, wood material is heated until the core temperature
          reaches 133&deg;F for at least 30 minutes. This ensures that any insects or larva
          in the wood is killed off and that the lumber is ready to be used and reused for
          international shipping. After heat treatment, the product is stampped with an
          <a href="https://en.wikipedia.org/wiki/ISPM_15#ISPM_Marking">
            internationally recognized marking
          </a> for future use.
          <br><br>
          Wood packing material which has been treated and marked in accordance with the ISPM-15
          standard and that has not been repaired, remanufactured or otherwise altered does not
          require re-treatment or reapplication of heat treatment marking throughout the service
          life of the unit.
          <br><br>
          For any order, we can work with certified heat treatment experts who will
          ensure that your load meets international safety and sanitary standards.
        </p>
        <hr>

        <h3>Shipping</h3>
        <p>
          We work with sellers and suppliers nationwide to deliver the materials you need
          at the lowest price no matter where you are.
          <br><br>
          We're your one-stop-shop for pallet needs. We supply all the pallets you need
          and buy all the material you don't. If you're a product manufacturer and need
          pallets to sell your product on, we bring the pallets to you. You're in pallet
          manufacturering? We buy your pallets and find the customers for you. We'll even
          pick up!
          <br><br>
          We will arrange your delivery anywhere you need it. If you have special transportation
          needs and would prefer to arrange transportation yourself, we can put you in direct
          contact with the transporters delivering your order. We are completely flexible!
        </p>
        <hr>

        <h3>Drop Trailer Services</h3>
        <p>
          Tight on space? Nowhere to warehouse a delivery of pallets? No problem!
          <br><br>
          With our drop trailer service, your delivery trailer will be left on premise for
          you to unload and use as you see fit. Any pallet cores or pallets that get damaged
          can be loaded back into the trailer and when you're done we'll buy it back from you
          and pick it up.
        </p>
        <hr>

        <h3>Recycled Pallet Quality</h3>
        <p>
          Recycled pallets are available in a few different quality grades from A to C based
          on how many repairs have been made and how much use the pallet has had.
          <br><br>
          There are a few compromises that come with recycled pallets but the cost savings
          can be as much as 60% which still gives them a place in shipping.
          <br><br>
          It's easy to think a recycled pallet doesn't hold as much weight as a new pallet,
          but despite the difference in appearance, the weight capacity of a recycled vs new
          pallet is very similar if that pallet was repaired by a reputable pallet supplier.
          <br><br>
          New pallets are made from fresh lumber and have very accurate dimensions. You
          may need accurate pallet sizes if you're working with high throughput automated
          processing. Recycled pallets are generally accurate in dimensions but may have
          some warping or bowing from previous use.
          <br><br>
          Since recyled pallets are already built and repairs are relatively quick, the
          turnaround time on an order of recycled pallets is much faster than a new
          order of custom built new pallets, even for efficient pallet manufacturerers.
        </p>
      </div>
      <div class="product-grid">
        <product-card
          v-for="slug in product.related"
          :key="slug"
          v-bind:product="findProduct(slug)"
        />
      </div>
    </article>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';
import ImageCarousel from '@/components/ImageCarousel';
import ProductCard from '@/components/ProductCard';
import productData from '@/data/Products';

export default {
  name: 'Product',
  components: {
    ContentWrapper,
    ImageCarousel,
    ProductCard,
  },
  data: () => ({
    product: {},
    expanded: false,
  }),
  methods: {
    updateProduct(newRoute) {
      this.product = this.findProduct(newRoute.params.slug);
    },
    findProduct(slug) {
      const { products } = productData;
      return products.find((product) => product.slug === slug);
    },
  },
  mounted() {
    this.updateProduct(this.$route);
  },
  watch: {
    $route(to) {
      this.updateProduct(to);
    },
  },
};
</script>

<style lang="scss">
article p {
  margin-bottom: 14px;
  white-space: pre-line;
  overflow-wrap: break-word;
}

ul:not(.vs-carousel__wrapper) {
  list-style-position: inside;
}

ul.expanding-list {
  margin-bottom: 14px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  &.expanded {
    max-height: 600px;
  }
}

.product-landing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-bottom: 10px;

}

.product-description {
  display: grid;
  padding: 0 3em;
  line-height: 1.6;
  word-spacing: 0.05em;

  .center {
    align-self: flex-end;

    a.button {
      margin: 0;
    }
  }
}

.center {
  text-align: center;

  a.button {
    text-transform: uppercase;
  }
}
</style>
