<template>
  <div>
    <div :class="{ skeleton: modal }" />
    <div :class="{ modal: modal, container: true }" @click="modalClicked">
      <span class="close" @click="hideModal">&times;</span>
      <span :class="{ counter: true, show: hover }">
        {{`${this.currentSlide}/${images?.length}`}}
      </span>
      <carousel
        class="carousel"
        aria-label="gallery"
        :hideArrowsOnBound="true"
        @page="pageEvent"
        @mouseover="this.hover = true"
        @mouseleave="this.hover = false"
        ref="carousel"
      >
        <!-- NOTE: there's a scroll-snapping bug in Firefox after node resizes -->
        <slide
          class="carousel-slide"
          v-for="{ webp, jpg, alt } in images"
          :key="jpg"
        >
          <v-lazy-image
            :src="webp"
            :alt="alt"
            use-picture
            :intersection-options="intersection"
            @click="showModal"
          >
            <source :srcset="webp" type="image/webp">
            <source :srcset="jpg" type="image/jpeg">
          </v-lazy-image>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-snap';
import VLazyImage from '@/components/VLazyImage';

export default {
  name: 'ImageCarousel',
  components: {
    Carousel,
    Slide,
    VLazyImage,
  },
  data: () => ({
    modal: false,
    intersection: {},
    hover: false,
    currentSlide: 1,
  }),
  props: ['images'],
  mounted() {
    // NOTE: this intersection option loads the image when
    // it is close to the edge of the scrolling box for the carousel
    // must wait for component to render before getting carousel for obs
    this.intersection.root = document.querySelector('.vs-carousel__wrapper');
  },
  methods: {
    async showModal() {
      if (this.modal) return;

      this.modal = true;
      // carousel component is watching these events - this improves some firefox resize bugs
      window.dispatchEvent(new Event('resize'));
      window.dispatchEvent(new Event('scroll'));
    },
    async hideModal() {
      if (!this.modal) return;

      this.modal = false;
      window.dispatchEvent(new Event('resize'));
      window.dispatchEvent(new Event('scroll'));
    },
    async modalClicked(event) {
      // if the sides of the li in the modal or the modal background is clicked
      const { classList } = event.target;
      if (classList.contains('carousel-slide') || classList.contains('modal')) {
        this.hideModal();
      }
    },
    pageEvent(e) {
      this.currentSlide = e.current + 1; // update slide counter
    },
  },
};
</script>

<style lang="scss">
:root {
  --modal-padding: 100px;
  --carousel-height: 400px;
}

.close {
  display: none; // hide unless modal
}

.counter {
  position: absolute;
  color: white;
  top: 6px;
  left: 12px;
  font-size: 28px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.show {
  display: block;
  opacity: 1;
}

.container {
  position: relative;
}

.skeleton {
  height: var(--carousel-height);
  background-color: lightgray;
  -moz-box-shadow: inset 0 0 4px #000000;
  -webkit-box-shadow: inset 0 0 4px #000000;
  box-shadow: inset 0 0 4px #000000;
}

.carousel {
  .carousel-slide {
    height: var(--carousel-height);
    background: linear-gradient(90deg, #fff, #ababab, 50%, #ababab, #fff);

    picture {
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;

      &.v-lazy-image-loaded {
        opacity: 1;
      }
    }
  }

  .vs-carousel__arrows {
    border: 0;
    height: 40%;
    color: white;
    font-family: 'Consolas', 'Arial Unicode MS';
    font-size: 56px;
    background-color: rgb(0,0,0); // Fallback color
    background-color: rgba(0,0,0,0.5); // Black w/ opacity
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: rgba(0,0,0,0.8);
    }
  }
}

.modal {
  position: fixed; // Stay in place
  z-index: 1; // Sit on top
  padding-top: var(--modal-padding);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); // Fallback color
  background-color: rgba(0,0,0,0.7); // Black w/ opacity
  animation: fadeIn 0.2s ease-out;

  .close { // close button
    display: block;
    position: absolute;
    color: white;
    top: 6px;
    right: 28px;
    font-size: 56px;
  }

  .close:hover,
  .close:focus {
    text-decoration: none;
    cursor: pointer;
  }

  .carousel {
    .carousel-slide {
      // override height set above to fit modal nicely
      height: calc(100vh - 2 * var(--modal-padding));
    }
  }

  .vs-carousel li img {
    object-fit: contain;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
